<template>
  <div class="header">
    <div class="nav">
      <!-- <div
        v-if="isCollapse"
        class="isCollapse el-icon-s-unfold"
        @click="$emit('update:isCollapse', false)"
      ></div>
      <div v-else class="isCollapse el-icon-s-fold" @click="$emit('update:isCollapse', true)"></div> -->
      <HorizontalMenuEle ref="HorizontalMenuEle" :isCollapse.sync="isCollapse"></HorizontalMenuEle>
    </div>
    <el-dropdown class="user">
      <div style="cursor: pointer; color: #fff">
        <img :src="userInfo.url | portrait" class="portrait" />
        {{ userInfo.userName }}
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="dialogVisible = true">
          <i class="el-icon-setting"></i>修改密码
        </el-dropdown-item>

        <el-dropdown-item @click.native="signOut">
          <i class="el-icon-circle-close"></i>退出登录
        </el-dropdown-item>
      </el-dropdown-menu>
      <el-dialog
        :visible.sync="dialogVisible"
        width="30%"
        title="修改密码"
        :modal-append-to-body="false"
      >
        原密码：<el-input placeholder="请输入原密码" v-model="oldPassword"></el-input>
        新密码：<el-input placeholder="请输入新密码" v-model="newPassword"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="getUpdatePassword">确 定</el-button>
        </span>
      </el-dialog>
    </el-dropdown>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    HorizontalMenuEle: () => import('@/views/common/HorizontalMenuEle.vue'),
  },
  props: {
    isCollapse: {
      type: Boolean,
      default: true,
    },
  },
  name: 'Header',
  data() {
    return {
      logo: require('@/assets/images/logo.png'),
      dialogVisible: false,
      oldPassword: '',
      newPassword: '',
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  methods: {
    signOut() {
      this.$store.commit({
        type: 'user/SIGN_OUT',
      })
      this.$router.push('/login')
    },
    getUpdatePassword() {
      let params = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      }
      this.$api.common
        .updatePassword(params)
        .then(res => {
          this.dialogVisible = false
          this.oldPassword = ''
          this.newPassword = ''
        })
        .catch(err => {})
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';

.isCollapse {
  width: 40px;
  font-size: 30px;
  text-align: center;
  cursor: pointer;
}
.header {
  display: flex;
  justify-content: space-between;
  background: #0a5d79 !important;
  .user {
    width: 150px;
    margin: 15px 15px 0 0;
    line-height: 40px;
    .portrait {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #f6f9fd;
      margin-right: 10px;
      border: #ddd solid 1px;
    }
  }

  .nav {
    min-width: calc(100% - 150px);
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    overflow: auto;
    padding-right: 10px;
  }
}

/* 滚动条样式修改 */
::-webkit-scrollbar {
  height: 8px;
  background-color: transparent;
  border-radius: 7px;
}
/*滚动条的轨道*/
::-webkit-scrollbar-track {
  /*box-shadow: inset 0 0 5px rgba(0,0,0,.3);*/
  background-color: transparent;
  border-radius: 8px;
}
/*滚动条的滑块按钮*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(144, 147, 153, 1);
  /*box-shadow: inset 0 0 5px #000;*/
}

/* 滚动条的滑块在非激活状态下的样式（滑块未被点击或拖动） */
::-webkit-scrollbar-thumb:inactive {
  background-color: transparent;
}

/* 滚动条的按钮（上下按钮） */
::-webkit-scrollbar-button {
  display: none;
}

/* 滚动条的角落（轨道和滑块交汇的部分） */
::-webkit-scrollbar-corner {
  background-color: transparent;
}
</style>
